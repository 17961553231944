import axios, { AxiosRequestConfig } from 'axios';

import { HOST_API } from '../constants/common';

export interface IAPI extends AxiosRequestConfig {
  formData?: boolean;
  axiosSetToken?: () => void;
  timeout?: number;
  data?: object;
}

export const API = async ({ url, method, headers, data, params, formData = false }: IAPI) => {
  if (formData) {
    headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      type: 'formData',
      project: 'showcase',
    };
  } else {
    headers = {
      ...headers,
      project: 'showcase',
    };
  }
  const axiosConfig = {
    method,
    baseURL: HOST_API,
    url,
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    timeout: 30000,
    data,
    params,
  };

  const responseAxios = await axios(axiosConfig);
  return responseAxios;
};

export const axiosSetToken = async () => {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
};
