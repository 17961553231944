import { IAddressItem } from './address';

// ----------------------------------------------------------------------

export type ICheckoutItem = {
  id: string;
  name: string;
  coverUrl: string;
  available: number;
  price: number;
  colors: string[];
  quantity: number;
  subTotal: number;
  size?: string;
};

export type ICheckoutDeliveryOption = {
  id: string;
  value?: number;
  label: string;
  description: string;
  imageUrl: string;
  price?: string;
  productId?: string;
};

export type ICheckoutPaymentOption = {
  value: string;
  label: string;
  description: string;
};

export type ICheckoutCardOption = {
  value: string;
  label: string;
};

export type ICheckoutValue = {
  total: number;
  subTotal: number;
  discount: number;
  shipping: number;
  activeStep: number;
  totalItems: number;
  items: ICheckoutItem[];
  billing: IAddressItem | null;
};

export type IBlockClubCard = {
  id: string;
  name: string;
  isEnable: boolean;
};

export type CheckoutContextProps = ICheckoutValue & {
  completed: boolean;
  //
  onAddToCart: (newItem: Omit<ICheckoutItem, 'subTotal'>) => void;
  onDeleteCart: (itemId: string) => void;
  //
  onIncreaseQuantity: (itemId: string) => void;
  onDecreaseQuantity: (itemId: string) => void;
  //
  onBackStep: VoidFunction;
  onNextStep: VoidFunction;
  onGotoStep: (step: number) => void;
  //
  onCreateBilling: (billing: IAddressItem) => void;
  onApplyDiscount: (discount: number) => void;
  onApplyShipping: (discount: number) => void;
  //
  canReset: boolean;
  onReset: VoidFunction;
};

export const PAYMENTH_METHODS = {
  card: 'card',
  clubCard: 'clubCard',
  cash: 'cash',
};

export type UsedVirtualCard = {
  id: string;
  name: string;
  cost: number;
};

export type CheckoutStateInterface = {
  completed: boolean;
  activeStep: number;
  billing: unknown;
  paymentMethod: string;
  clubCardId: string;
  deliveryMethod: string;
  deliveryCost: number;
  isPaymentFailed: boolean;
  usedVirtualCards: UsedVirtualCard[];
};
