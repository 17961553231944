import axios, { AxiosRequestConfig } from 'axios';
import useSWRHook, { SWRConfiguration } from 'swr';

import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

const config: SWRConfiguration = { errorRetryCount: 3 };
export const useSWR = (request: any, argConfig?: SWRConfiguration) =>
  useSWRHook(request, fetcher, { ...config, ...argConfig });

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    getAll: 'product/getAll',
    list: '/product/getAll',
    byId: 'product',
    search: '/api/product/search',
    categories: 'product-category/getAll',
    categoryById: 'product-category',
    getTags: 'product/getTags',
    byMerchantId: 'product/getProductsByMerchantId',
  },
  order: {
    list: '/order/getOrders',
    details: '/api/product/details',
  },
  basket: {
    create: '/cart',
    details: '/cart',
    update: '/cart',
    clear: '/cart/clear',
    checkout: '/cart/fastCheckout',
    splitCheckout: 'cart/splitCheckout',
    pelecardCheckout: '/cart/pelecardCheckout',
  },
  card: {
    all: 'card/getAllCards',
  },
  merchant: {
    all: 'merchant/all',
    allExisted: 'merchant/allExisted',
    byDomain: '/merchant?domain=',
    categories: 'merchant-category/all',
    byId: '/merchant',
    activities: '/smbActivity/getActivitiesByMerchantId',
    clubCards: '/virtualCardType/getAllBySmbAccountId',
    getTags: 'merchant/getTags',
    byProductId: 'merchant/getMerchantsByProduct',
    byVirtualCardId: 'merchant/getMerchantByVirtualCardTypeId',
  },
  generator: {
    getSections: '/siteBuilder/getAllSectionsByActivityId',
  },
  activity: {
    getActivity: '/smbActivity/getActivityByDomain',
  },
  domain: {
    getDomain: '/getActivityByDomain',
  },
  category: {
    all: 'product-category/getAll',
    byId: 'product-category',
  },
  virtualCardType: {
    getById: '/virtualCardType/getById',
    getAllBySmbAccountId: '/virtualCardType/getAllBySmbAccountId',
    pelecard: '/virtualCard/topUpVirtualCard',
    transfer: '/virtualCard/transfer',
  },
  networks: {
    all: '/network/all',
    byId: 'network',
    getTags: 'network/getTags',
  },
  report: {
    getAll: '/report',
  },
  notifications: {
    getCount: '/notifications/count',
    getUnread: '/notifications/list/unread',
    getRead: '/notifications/list/read',
    getAll: '/notifications/list/all',
    settings: '/notifications/settings',
    markAsViewed: '/notifications/markAsViewed',
    action: '/notifications/action',
  },
};
