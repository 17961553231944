'use client';

import { useContext, createContext } from 'react';

import { ContentContextProps } from '../types';

// ----------------------------------------------------------------------

export const ContentContext = createContext({} as ContentContextProps);

export const useContentContext = () => {
  const context = useContext(ContentContext);

  if (!context) throw new Error('useContentContext must be use inside ContentProvider');

  return context;
};
