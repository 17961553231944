import { API } from 'src/helpers/api';
import { BOT_ID } from 'src/config-global';
import { ActionType, ActionTypes } from 'src/contexts/AppContext';

export const getKosher = async (
  dispatch?: React.Dispatch<ActionType>,
) => {
  try {
    const response = await API({
      url: '/attribute?search=kosher',
      method: 'GET',
    });

    const kosherId = response?.data?.payload?.[0]?.id;

    if (kosherId) {

      const { data } = await API({
        url: `/attribute/by-ids?ids[]=${kosherId}`,
        method: 'GET',
      });

      if (dispatch) {
        dispatch({
          type: ActionTypes.GET_KOSHER,
          payload: data?.payload?.[0]?.attributeValue,
        });
      }
    } else {
      console.warn('ID не найден');
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};


export const getLocation = async (
  dispatch?: React.Dispatch<ActionType>,
) => {
  try {
    // Выполнение первого запроса для получения location
    const response = await API({
      url: '/attribute?search=location',
      method: 'GET',
    });

    const locationId = response?.data?.payload?.[0]?.id;

    if (locationId) {

      const { data } = await API({
        url: `/attribute/by-ids?ids[]=${locationId}`,
        method: 'GET',
      });

      if (dispatch) {
        dispatch({
          type: ActionTypes.GET_LOCATION,
          payload: data?.payload?.[0]?.attributeValue,
        });
      }
    } else {
      console.warn('ID не найден');
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

