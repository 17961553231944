export const getDomainName = () => {
  if (process.env.NEXT_PUBLIC_TEST_DOMAIN_NAME) {
    return process.env.NEXT_PUBLIC_TEST_DOMAIN_NAME;
  }

  let domain = '';

  const name = !(typeof window === 'undefined') ? window.location.host.split('.')[0] : 'xtra';
  if (name === 'www') {
    domain = !(typeof window === 'undefined') ? window.location.host.split('.')[1] : 'xtra';
  } else {
    domain = !(typeof window === 'undefined') ? window.location.host.split('.')[0] : 'xtra';
  }
  return domain;
};
