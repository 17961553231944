import { endpoints } from 'src/utils/axios';

import { API } from 'src/helpers/api';
import { BOT_ID } from 'src/config-global';
import { ActionType, ActionTypes } from 'src/contexts/AppContext';

import { CartInterface } from 'src/types/cart';

import { UsedVirtualCard } from '../types/checkout';
import { getDomainName } from '../utils/client-common';

export const getBasket = async (smbAccountId: string, dispatch: React.Dispatch<ActionType>) => {
  try {
    const { data } = await API({ url: 'cart', method: 'GET', data: { smbAccountId } });
    dispatch({ type: ActionTypes.GET_BASKET, payload: data });
    return data;
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    return null;
  }
};

export const increseProduct = async (
  row: CartInterface,
  smbAccountId: string,
  dispatch: React.Dispatch<ActionType>
) => {
  try {
    const quantity = row.quantity >= row?.amount ? row?.amount : row.quantity + 1;
    const newItem = {
      cartItemId: row.id,
      productId: row.productId,
      quantity,
      priceAtPurchase: row.price,
    };
    await API({ url: 'cart', method: 'PUT', data: newItem });
    await getBasket(smbAccountId, dispatch);
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};

export const addToBasket = async (
  smbAccountId: string,
  productId: string,
  price: number,
  imageLink: string,
  dispatch: React.Dispatch<ActionType>,
  cart: CartInterface[],
  attributeValueId?: string[],
  deliveryOptionId?: string,
) => {
  try {
    let activityId = '';
    const response = await API({
      url: `/smbActivity/getActivityByDomain?domain=${getDomainName()}`,
      method: 'GET',
    });
    if (response.data && response.data.payload) {
      activityId = response?.data.payload.id;
    }
    if (imageLink) {
      localStorage.setItem(productId, imageLink);
    }
    const result = cart && cart.find((item: CartInterface) => item.product.id === productId);
    const forbidden = result && result.quantity > result.amount;

    if (!forbidden) {
      const newItem = {
        productId,
        ...(deliveryOptionId && { deliveryOptionId }),
        quantity: 1,
        priceAtPurchase: price,
        attributeValueId: attributeValueId || [],
        activityId,
      };
      await API({ url: 'cart', method: 'POST', data: newItem });
      await getBasket(smbAccountId, dispatch);
    }
    return null;
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    return null;
  }
};

export const decreaseProduct = async (
  row: CartInterface,
  smbAccountId: string,
  dispatch: React.Dispatch<ActionType>
) => {
  if (row.quantity === 1) return;
  try {
    const newItem = {
      cartItemId: row.id,
      productId: row.productId,
      quantity: row.quantity - 1,
      priceAtPurchase: row.price,
    };
    await API({ url: 'cart', method: 'PUT', data: newItem });
    await getBasket(smbAccountId, dispatch);
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};

export const removeProduct = async (
  cartId: string,
  smbAccountId: string,
  dispatch: React.Dispatch<ActionType>
) => {
  try {
    await API({ url: 'cart', method: 'DELETE', data: { cartId } });
    await getBasket(smbAccountId, dispatch);
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};

export const clearBasket = async (productId: string, dispatch: React.Dispatch<ActionType>) => {
  try {
    const { data } = await API({ url: 'cart', method: 'GET' });
    dispatch({ type: ActionTypes.GET_BASKET, payload: data });
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};

export const checkout = async (
  clubCarId: string,
  smbAccountId: string,
  smbActivity: string | null,
  dispatch: React.Dispatch<ActionType>,
  documentImageUrls: string[] = [],
  orderStatus: string = '',
  userId: string = ''
) => {
  try {
    const { data } = await API({
      url: 'cart/checkout',
      method: 'POST',
      data: {
        virtualCardId: clubCarId,
        smbAccountId,
        smbActivity,
        documentImageUrls,
        ...(orderStatus && { orderStatus }),
        userId,
      },
    });

    if (data.order) {
      dispatch({ type: ActionTypes.CHECKOUT_COMPLETE, payload: data.order.id });
      dispatch({ type: ActionTypes.SET_NEW_ORDER, payload: data.order });
    }

    // await getOrderById(data.orderId, dispatch);
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};

export const changeOrderStatus = async (
  dispatch: React.Dispatch<ActionType>,
  orderId: string,
  orderStatus: string
) => {
  try {
    const { data } = await API({
      url: `order/changeOrderStatus/${orderId}?orderStatus=${orderStatus}`,
      method: 'POST',
    });

    if (data && data.status === 'PAID') {
      dispatch({ type: ActionTypes.SET_NEW_ORDER, payload: data });
    }

    return { success: true };
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });

    return { success: false };
  }
};

export const cancelOrder = async (dispatch: React.Dispatch<ActionType>, orderId: string) => {
  try {
    const { data } = await API({
      url: `order/cancelOrder/${orderId}`,
      method: 'POST',
    });

    if (data && data.status === 'CANCELLED') {
      dispatch({ type: ActionTypes.SET_NEW_ORDER, payload: data });
    }

    return { success: true };
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });

    return { success: false };
  }
};

export const splitCheckout = async (
  cards: UsedVirtualCard[],
  smbAccountId: string,
  language: string,
  dispatch: React.Dispatch<ActionType>
) => {
  try {
    const currentOrigin = window.location.origin;
    const { data } = await API({
      url: endpoints.basket.splitCheckout,
      method: 'POST',
      data: {
        smbAccountId,
        botId: BOT_ID,
        successUrl: `${currentOrigin}/${language}/pelecard-success?`,
        errorUrl: `${currentOrigin}/${language}/pelecard-error?`,
        virtualCard: cards.map((card: UsedVirtualCard) => ({
          virtualCardId: card.id,
          amount: card.cost,
        })),
      },
    });

    if (!data.success) {
      throw new Error(data.message || 'Something went wrong');
    }

    if (data.order) {
      dispatch({ type: ActionTypes.CHECKOUT_COMPLETE, payload: data.order.id });
      dispatch({ type: ActionTypes.SET_NEW_ORDER, payload: data.order });
    }

    return data.IFrameUrl;
  } catch (e) {
    return '';
  }
};

export const checkoutPelecard = async (smbActivity: string | null, smbAccountId: string, language: string,) => {
  try {
    const currentOrigin = window.location.origin;
    const { data } = await API({
      url: endpoints.basket.pelecardCheckout,
      method: 'POST',
      data: {
        botId: BOT_ID,
        successUrl: `${currentOrigin}/${language}/pelecard-success?`,
        errorUrl: `${currentOrigin}/${language}/pelecard-error?`,
        smbAccountId,
        smbActivity
      },
    });
    console.log('data', data);
    if (!data.success || !data.IFrameUrl) {
      throw new Error(data.message || 'Something went wrong');
    }
    return data.IFrameUrl;
  } catch (e) {
    return '';
  }
};

export const completeCheckout = (dispatch: React.Dispatch<ActionType>) => {
  dispatch({ type: ActionTypes.CHECKOUT_COMPLETE, payload: '' });
};

export const completeCheckoutWithError = (dispatch: React.Dispatch<ActionType>) => {
  dispatch({ type: ActionTypes.CHECKOUT_COMPLETE_ERROR, payload: '' });
};
