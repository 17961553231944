import { API } from 'src/helpers/api';
// import { SERVICES } from 'src/_mock/_services';
import { ActionType, ActionTypes } from 'src/contexts/AppContext';

import { BOT_ID } from '../config-global';

export type IAddVirtualCardChild = {
  firstName: string;
  lastName: string;
  phone: string;
  virtualCardTypeId: string;
  smbActivityId: string;
  merchantId: number;
  customerFromCrm: string;
};

export const getVirtualCards = async (activityId: string, dispatch: React.Dispatch<ActionType>) => {
  try {
    const url = `virtualCard/getMyVirtualCards?activityId=${activityId}`;
    const { data } = await API({
      url,
      method: 'GET',
      // relations: ['tags']
    });
    dispatch({ type: ActionTypes.GET_VIRTUAL_CARD, payload: data.payload });

    // switch to mock virtualCards
    // dispatch({ type: ActionTypes.GET_VIRTUAL_CARD, payload: SERVICES });
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};

export const getAllVirtualCards = async (
  dispatch: React.Dispatch<ActionType>,
  smbAccountId: string
) => {
  try {
    const url = 'virtualCardType/getAllBySmbAccountId';
    const { data } = await API({
      url,
      method: 'GET',
      params: {
        botId: BOT_ID,
        active: true,
        smbAccountId,
      },
    });
    dispatch({ type: ActionTypes.GET_ALL_VIRTUAL_CARD, payload: data.payload });
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};

export const getVirtualCardsByCustomerId = async (id: string) => {
  try {
    const url = `virtualCardType/getAllBySmbAccountId/${id}`;
    const { data } = await API({
      url,
      method: 'GET',
    });
    return data;
  } catch (e) {
    console.error('Error fetching virtual cards:', e);
    return null;
  }
};

export const addVirtualCardToChild = async (body: IAddVirtualCardChild) => {
  try {
    const url = `/family/addChild`;
    const { data } = await API({
      url,
      method: 'POST',
      data: body,
    });
    return data;
  } catch (e) {
    console.error('Error:', e);
    return null;
  }
};

export const addMemberToFamily = async (body: {
  customerId: string;
  redirectUrl: string;
  customerFromCrm: string;
  botId?: number;
}) => {
  try {
    body.botId = +BOT_ID;
    const url = `/family/addMember`;
    const { data } = await API({
      url,
      method: 'POST',
      data: body,
    });
    return data;
  } catch (e) {
    console.error('Error:', e);
    return null;
  }
};
